<template>
  <div class="Login">
    <img class="bar" src="../../assets/login_bg.png">

    <div class="content">
      <div class="item">
        <div class="title">{{ $t('phone') }}</div>
        <div class="phone">
          <mt-field
            :placeholder="$t('phonePlaceholder')"
            v-model="form.phone"
            type="tel"
            v-mask="'#### #### ##'"
            :state="phoneState"
          ></mt-field>
        </div>
      </div>
      <div class="item" v-if="navbar == 0">
        <div class="title">{{ $t('enterPassword') }}</div>
        <mt-field
          :placeholder="$t('passwordPlaceholder')"
          :type="!visible? 'password': 'text'"
          v-model="form.password"
          :state="passwordState"
        >
          <img v-if="!visible" class="visible" src="../../assets/notVisible.png" height="20px" @click="visible = true">
          <img v-else class="visible" src="../../assets/browse.png" height="20px" @click="visible = false">
        </mt-field>
      </div>
      <div class="item" v-else>
        <div class="title">{{ $t('validateCode') }}</div>
        <div class="code">
          <mt-field :placeholder="$t('codePlaceholder')" v-model="form.validateCode" :state="validateCodeState" />
          <mt-button type="primary" size="small" :disabled="!!time" @click="getSmsCode('LOGIN')">{{
            $t("getVerificationCode")
          }}{{ time? `(${time})`: '' }}</mt-button>
        </div>
        <!-- <div class="ivr">
          {{ $t("youCanTry") }}
          <mt-button size="small" plain @click="getIvrCode('REGISTER')" :disabled="!!time">{{ $t("getIvrCode") }}{{ time? `(${time})`: '' }}</mt-button>
        </div> -->
      </div>
      <div class="navbar">
        <div v-if="navbar == 1" @click="navbar = 0">
          {{ $t('passwordLogin') }}
        </div>
        <div v-else @click="navbar = 1">
          {{ $t('verificationCodeLogin') }}
        </div>
      </div>
      <div class="submit">
        <mt-button type="primary" size="large" @click="submit">{{
          $t("login")
        }}</mt-button>
      </div>
    </div>

    <div class="bottom">
      <div @click="onRegister">{{ $t("register") }}</div>
      <div @click="onPassword">{{ $t("passwordTips") }}</div>
    </div>

  </div>
</template>

<script>
import { phoneRules, validateCodeRules, passwordRules } from "../../utils/rules";
import { mapState, mapMutations } from "vuex";
import { session } from '../../utils/api'
import { geolocation } from '../../utils/index'
import mixin from './mixin'
export default {
  name: "Login",
  mixins: [mixin],
  components: {},
  data() {
    return {
      visible: false,
      navbar: 0,
    };
  },
  computed: {
    ...mapState(["record"]),
    phoneState() {
      this.SET_RECORD_ITEM({
        key: "loginPhone",
        value: this.form.phone,
      });
      return this.isCheck ? phoneRules(this.form.phone) : null;
    },
    validateCodeState() {
      return this.isCheck ? validateCodeRules(this.form.validateCode) : null;
    },
    passwordState() {
      this.SET_RECORD_ITEM({
        key: "loginPassword",
        value: this.form.password,
      });
      return this.isCheck ? passwordRules(this.form.password) : null;
    },
  },
  created() {
    this.form.phone = this.record.loginPhone || "";
    this.form.password = this.record.loginPassword || "";
  },
  methods: {
    ...mapMutations(["SET_RECORD_ITEM", "SET_ACCESS_TOKEN"]),
    onPassword() {
      this.SET_RECORD_ITEM({
        key: "passwordPhone",
        value: this.form.phone,
      });
      this.$router.push('/user/password');
    },
    onRegister() {
      this.SET_RECORD_ITEM({
        key: "registerPhone",
        value: this.form.phone,
      });
      this.$router.push('/user/register');
    },
    async submit() {
      this.isCheck = true;
      var error = this.validation(this.navbar == 0? ['phone', 'password']: ['phone', 'validateCode']);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();
      await this.$axios({
        method: "post",
        url: session,
        data: {
          loginType: this.navbar == 0? 'PWD_LOGIN': 'CODE_LOGIN',
          phone: this.form.phone.replace(/\s/g, ""),
          password: this.form.password,
          code: this.form.validateCode,
          deviceId: "H5",
          isValidateCode: 'Y',
          gps: await geolocation()
        },
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_ACCESS_TOKEN(e.body.accessToken);
            await this.getUser();
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done();
    },
  },
};
</script>

<style scoped lang="scss">
.Login {
  background-color: #F8F6EF;
  .bar {
    width: 100%;
  }
  .navbar {
    text-align: center;
    color: #4F9B41;
    margin-top: 20px;
    font-size: 14px;
  }
  .content {
    padding: 15px;
    /deep/ .mint-cell-wrapper,
    /deep/ .mint-cell:last-child {
      background-image: none;
    }
    .item {
      margin-top: 25px;
      .title {
        margin: 6px 15px;
        font-size: 12px;
        color: #1C252A;
      }
      .phone {
        border-radius: 6px;
        display: flex;
        background-color: white;
        .mint-cell {
          flex: 1;
        }
        &::before {
          content: '+52';
          color: #111111;
          padding-left: 10px;
          line-height: 30px;
        }
      }
      .mint-field {
        border-radius: 6px;
        min-height: 30px;
      }
      .code {
        display: flex;
        .mint-field {
          flex: 1;
        }
        .mint-button {
          background-color: #FFDF0A;
          border-radius: 6px;
          height: 46px;
          padding: 0px 25px;
          margin-left: 4px;
          font-size: 13px;
        }
      }
    }
    .ivr {
      color: white;
      margin: 10px 15px 0px 15px;
      .mint-button {
        color: white;
        border-color: white;
      }
    }
    .submit {
      padding: 20px 0px 0px 0px;
      .mint-button {
        background-color: #4F9B41;
        border-radius: 6px;
        font-weight: 600;
      }
    }
  }
  .separation {
    position: relative;
    text-align: center;
    color: white;
    margin: 0 15px;
    &::after, &::before {
      content: '';
      position: absolute;
      height: 1px;
      background-color: white;
      top: 50%;
      width: 40%;
      transform: translateY(-50%);
    }
    &::after {
      left: 0px;
    }
    &::before {
      right: 0px;
    }
  }
  .visible {
    margin-left: 5px;
  }
  .bottom {
    display: flex;
    padding-bottom: 20px;
    color: #4F9B41;
    font-size: 14px;
    div {
      flex: 1;
      text-align: center;
    }
  }
}
</style>
